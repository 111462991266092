import { Component, OnInit, Input, OnDestroy, Renderer2, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from 'app/core/services/layout.service';
import { ThemeService } from 'app/core/services/theme.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { PORTALTYPE } from 'app/core/helpers/enum.helper';
import { UserService } from 'app/core/services/user.service';
import { PermissionService } from 'app/core/services/permission.service';
import { decodedToken } from 'app/core/helpers/token.helper';
import { get } from "lodash";
import { EmployeeService } from 'app/core/services/employee.service';
import { GenerateRouteService } from 'app/core/services/generate-route.service';
import { Router } from '@angular/router';
import { MessagingService } from 'app/core/services/messaging.service';
import { RouterActiveService } from 'app/core/services/router-active.service';
import { AppLoaderService } from 'app/core/services/app-loader.service';
import { BellCountService } from 'app/core/services/bell-count.service';
import { map } from 'rxjs/operators';
import { ToastService } from 'app/core/services/toast.service';
Router
@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html'
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  isSettingsRouteSub: Subscription
  tgssThemes: any[] = [];
  currentLang = 'en';
  availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }]
  @Input() notificPanel;

  portalType: string = '';
  userType: string = '';
  queueCount = 0;
  checkType: any = PORTALTYPE;
  userName: any;
  name: any;
  isInSettingsMenu: boolean = false;
  settingsIndex: any = 0;
  image: any;
  active: any = ''

  constructor(
    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private userService: UserService,
    private toast: ToastService,
    private employeeservice: EmployeeService,
    public permission: PermissionService,
    private generateRouteService: GenerateRouteService,
    private router: Router,
    private messagingService: MessagingService,
    private routerActive: RouterActiveService,
    private loader: AppLoaderService,
    public countService: BellCountService

  ) {
    this.checkSettingsMenu();
    this.autoInit();
    const token = window.localStorage.getItem("TGJWTTOKEN");
    this.messagingService.requestPermission(token);
    this.messagingService.receiveMessage();
  }


  @HostListener('window:resize', ['$event'])

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.tgssThemes = this.themeService.tgssThemes;
    this.menuItemSub = this.navService.menuItems$
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit: any
        if (window.matchMedia(`(max-width: 1251px) and (min-width: 1100px)`).matches) {
          limit = 4;
        } else if (window.matchMedia(`(max-width: 1099px)`).matches) {
          limit = 5;
        } else if (window.matchMedia(`(max-width: 1281px)`).matches) {
          limit = 6;
        } else if (window.matchMedia(`(max-width: 1460px) and (min-width: 1282px)`).matches) {
          limit = 6;
        } else if (window.matchMedia(`(max-width: 1536px) and (min-width: 1460px)`).matches) {
          limit = 9;
        } else if (window.matchMedia(`(min-width: 1600px)`).matches) {
          limit = 10;
        }
        // let limit = window.matchMedia(`(max-width: 1372px)`).matches  ? 10 :  window.matchMedia(`(max-width: 1222px)`).matches ? 9 : window.matchMedia(`(max-width: 1142px)`).matches ? 8 : '11';
        let mainItems: any[] = res.slice(0, limit)
        // console.log(res.length <= limit, res.length, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length)

        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })

        this.menuItems = mainItems
      })
    this.active = this.routerActive.activeRouter.pipe(ele => ele)
    this.getQueueValue()
    this.getBellCount()
  }

  bellCount: any = 0
  getBellCount() {
    this.bellCount = this.countService.getBellCount()
  }

  setCount() {
    // this.countService.setBellCount(Math.random().toFixed(2))
  }

  ngOnDestroy() {
    this.menuItemSub.unsubscribe();
    this.isSettingsRouteSub.unsubscribe();
  }

  setLang() {
    this.translate.use(this.currentLang)
  }


  getQueueValue() {
    this.generateRouteService.queueCount.subscribe((res) => {
      this.queueCount = res >= 0 ? res : 0;
    });
  }
  changeTheme(theme) {
    this.layout.publishLayoutChange({ matTheme: theme.name })
  }

  async toggleNotific() {
    let count = 0
    this.bellCount.subscribe((res: any) => {
      count = res
    })

    if (count > 0) {
      this.countService.hitBell()
      await this.notificPanel.toggle();
    } else {
      if (decodedToken().portal == 'admin') {
        this.router.navigate([`notification/list`]);
      } else {
        this.router.navigate([`employee/notification/list`]);
      }
    }

  }


  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  isMd() {
    return window.matchMedia(`(max-width: 1372px)`).matches;
  }


  is() {
    return window.matchMedia(`(max-width: 1222px)`).matches;
  }

  // gotoDash() {
  //   // [routerLink]="'/dashboard'"
  //   this.loader.open()
  //   setTimeout(() => {
  //     this.loader.close()
  //     this.router.navigate([`/dashboard`]) ? this.router.navigate([`/employee/employee-dash`]) : ''
  //   }, 100);
  // }

  gotoDash() {

    const tokens = decodedToken();
    console.log(tokens?.portal, 'token portal');
    if (tokens?.portal == 'admin') {
      this.router.navigate(["/dashboard"]);
    } else {
      this.router.navigate([`/employee/employee-dash`])
    }
  }

  autoInit() {
    const token = decodedToken();
    this.portalType = get(token, 'portal') ?? '';
    this.userType = get(token, 'user_type') ?? '';
    this.userName = this.portalType == PORTALTYPE.ADMIN ? get(token, 'name') : get(token, 'email');
    console.log(this.userName,"this.userNamethis.userName")
    this.name = get(token, 'name');
    this.image = get(token, 'profile_image'); //  employee_image
  }




  logout(portalType) {
    const tokens = decodedToken();
    console.log(tokens, 'token ');
    this.messagingService.deleteToken();
    this.messagingService.sendFCMToken(null);
    if (portalType == PORTALTYPE.ADMIN) {
      this.userService.logout();
      this.postLogout(tokens)
    } else if (portalType == PORTALTYPE.EMPLOYEEE) {
      this.employeeservice.logout();
      this.postLogout(tokens)
    }
    else {
      this.userService.logout();
      this.postLogout(tokens)
    }
    this.navService.sendNotificationWidget('')
  }

  postLogout(token) {
    const formData = new FormData()
    formData.append('user_id', token?.acl_user_id)
    this.userService.create(formData).subscribe((res: any) => {
      if (res?.keyword == 'success') {
        //this.toast.showSuccessToast('successfully post')
      } else {

      }
    }, (err: any) => {

    })
  }



  getLink(item) {
    // console.log(item, "state",item?.sub);
    // console.log(item.sub[0].state, "13421343434343434343434343434");
    if (item?.name === "Settings") {
      if (decodedToken().acl_role_id != 1) {
        return this.getRouterLink()
      } else {
        return 'settings/category-subcategory'
      }

    } else {
      return item?.state
    }
  }
  goToCalender() {
    this.router.navigate(['/employee/calender']);
  }



  checkSettingsMenu() {
    this.isSettingsRouteSub = this.userService.isInSettingsMenu.subscribe((res: any) => {

      if (res) {
        this.isInSettingsMenu = res.isIn ?? false;
        this.settingsIndex = res?.index ?? 0;
      }
    })
  }

  getRouterLink(): String {
    const self = this
    function has(modulename): boolean {
      return self.permission.hasPermission([modulename])
    }
    switch (true) {
      // case has('Dashboard'): return '/dashboard';
      // case has('Passport Size photo'): return '/passportsize-photo';
      // case has('Photo Print'): return '/photo-print';
      // case has('Photo Frame'): return '/Photoframe';
      // case has('Personalized Products'): return '/personalized-products';
      // case has('E-commerce Products'): return '/e-commerce-product';
      // case has('Selfie Album'): return '/selfie-album';
      // case has('Waiting Payments'): return '/waiting-payments';
      // case has('Waiting Dispatch'): return '/waiting-dispatch';
      // case has('Delivery Details'): return '/delivery-details';
      // case has('Cancelled Details'): return '/cancelled-details';
      // case has('Billing Management'): return '/billing-management';
      // case has('Track Order'): return '/track-order';
      // case has('Customer'): return '/customer-details';
      // case has('Employee'): return '/employee-details';
      // case has('Task Manager'): return '/task-manager';
      // case has('Payment Transaction'): return '/payment-transaction';
      // case has('Refund'): return '/refund';
      // // CMS
      // case has('CMS Banner'): return '/cms/banner';
      // case has('CMS Video'): return '/cms/video';
      // case has('CMS Greetings'): return '/cms/greeting';
      // // Others
      // case has('Contest'): return '/contest';
      // case has('Tickets'): return '/ticket';
      // case has('Management communication'): return '/manangenet-communication';
      // Reports
      // case has('Order Report'): return '/reports/orders-report';
      // case has('Refund Report'): return '/reports/refund-report';
      // case has('Tickets Report'): return '/reports/ticket-report';
      // case has('Gst Report'): return '/reports/gst-report';
      // case has('Task Report'): return '/reports/task-report';
      // case has('Ratings Review Report'): return '/reports/ratings-review-report';
      // case has('Customer Report'): return '/reports/customer-report';
      // case has('Employee Report'): return '/reports/employee-report';
      // case has('Product Report'): return '/reports/product-report';
      // case has('Stock Report'): return '/reports/stock-report';
      // case has('Payment Transaction Report'): return '/reports/payments';
      // Settings
      case has('Category'): return '/settings/category-subcategory';
      case has('Variant Type'): return '/settings/variant-type';
      // case has('Delivery Management'): return '/settings/delivery-charge';
      // case has('Expected Delivery Days'): return '/settings/expected-delivery';
      // case has('Shipped Vendor'): return '/settings/shipped-vendor';
      case has('Ratings & Review'): return '/settings/ratings-review';
      case has('Department'): return '/settings/department';
      case has('Task Stages'): return '/settings/task-stages';
      case has('QR Code'): return '/settings/qr-code';
      case has('Task Duration'): return '/settings/task-duration';
      case has('Photo Print Setting'): return '/settings/photo-printing';
      case has('GST Percentage'): return '/settings/gst-percentage';
      case has('Other District Setting'): return '/settings/other-district';
      case has('Change Password'): return '/settings/change-password';
      case has('Coupon Code'): return '/settings/coupon-code';
      case has('Manage Roles'): return '/settings/role';
      case has('Manage Users'): return '/settings/users';
      case has('Terms and conditions'): return '/settings/terms-conditions';
    }

  }




}
